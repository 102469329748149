"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
require("@/node_modules/angularjs-slider/dist/rzslider.js");
const SoundBox = require('sound-box').default;
const random = (min, max, exclude) => {
    let r = Math.floor(Math.random() * (max + 1 - min)) + min;
    while ((r == exclude) && (max - min) >= 1) {
        r = Math.floor(Math.random() * (max + 1 - min)) + min;
    }
    return r;
};
class SoundsCtrl {
    constructor($scope, $filter, $q) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.$q = $q;
        this.version = 1;
        this.defaultLevel = 80;
        this.sliderOptions = {
            floor: 0,
            ceil: 100,
            step: 1,
            hideLimitLabels: true,
            hidePointerLabels: true,
            defaultVolume: this.defaultLevel,
            onChange: ((sliderId, modelValue, highValue, pointerType) => {
                const volume = modelValue / 100;
                this.soundboxMap[sliderId.key].instances.map((audio) => {
                    audio.volume = volume;
                });
            }).bind(this),
            // onEnd: ((sliderId: ISound, modelValue: any, highValue: any, pointerType: any) => {
            //     sliderId.level = modelValue
            // }).bind(this),
        };
        this.presets = {
            productivity: [
                [
                    { key: 'frog', level: 25 },
                    { key: 'rain', level: 75 },
                ],
                [
                    { key: 'campfire', level: 25 },
                    { key: 'rain', level: 75 },
                ],
            ],
            relax: [
                [
                    { key: 'rain_on_the_roof', level: 98 },
                ],
                [
                    { key: 'frog', level: 37 },
                    { key: 'campfire', level: 65 },
                ],
            ],
            meditation: [
                [
                    { key: 'frog', level: 37 },
                    { key: 'rain', level: 65 },
                    { key: 'campfire', level: 65 },
                ],
            ],
            sleep: [
                [
                    { key: 'thunder', level: 37 },
                    { key: 'campfire', level: 65 },
                ],
            ],
        };
        this.soundboxMap = {};
        this.presetSelected = {};
        this.sounds = [
            Object.assign({ key: 'rain', sound: require('./sounds/rain.mp3').default, iconClass: 'fa-solid fa-cloud-showers-heavy', title: this.$filter('translate')('Rain'), level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain_${this.version}`) || '{}')),
            Object.assign({ key: 'frog', sound: require('./sounds/frogs.mp3').default, title: this.$filter('translate')('Frog'), iconClass: 'fa-solid fa-frog', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`frog_${this.version}`) || '{}')),
            Object.assign({ key: 'campfire', sound: require('./sounds/campfire.mp3').default, title: this.$filter('translate')('Campfire'), iconClass: 'fa-solid fa-fire', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`campfire_${this.version}`) || '{}')),
            Object.assign({ key: 'thunder', sound: require('./sounds/thunder.mp3').default, title: this.$filter('translate')('Thunder'), iconClass: 'fa-solid fa-cloud-bolt', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`thunder_${this.version}`) || '{}')),
            Object.assign({ key: 'rain_on_the_roof', sound: require('./sounds/rain_on_the_roof.mp3').default, title: this.$filter('translate')('Rain on the roof '), iconClass: 'fa-solid fa-cloud-bolt', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain_on_the_roof_${this.version}`) || '{}')),
        ];
        this.pause = true;
        this.canPlay = false;
    }
    $onInit() {
        this.$scope.$watch('$ctrl.sounds', (sounds) => {
            this.canPlay = sounds.filter((item) => item.play).length != 0;
            if (!this.canPlay) {
                this.pause = true;
            }
            for (let item of sounds) {
                localStorage.setItem(`${item.key}_${this.version}`, JSON.stringify({
                    level: item.level,
                    play: item.play,
                }));
                if (!item.play) {
                    this.soundboxMap[item.key].stop_all();
                }
            }
        }, true);
        this.$q.all([...this.sounds.map((item) => {
                const soundbox = new SoundBox();
                this.soundboxMap[item.key] = soundbox;
                return soundbox.load(item.key, item.sound).then(() => {
                    item.loaded = true;
                });
            })]);
    }
    getSliderOptions(item) {
        return Object.assign(Object.assign({}, this.sliderOptions), { id: item, disabled: !item.play });
    }
    togglePlay(item) {
        item.play = !item.play;
        this.pause = false;
        for (let sound of this.sounds) {
            if (sound.play) {
                this.muteOn(sound);
            }
            else {
                this.muteOff(sound);
            }
        }
    }
    toggleMute() {
        this.pause = !this.pause;
        for (let sound of this.sounds) {
            if (sound.play) {
                if (this.pause) {
                    this.muteOff(sound);
                }
                else {
                    this.muteOn(sound);
                }
            }
        }
    }
    muteOn(sound) {
        const soundbox = this.soundboxMap[sound.key];
        const instances = soundbox.instances;
        if (instances.length == 0) {
            soundbox.play(sound.key, undefined, sound.level / 100, true);
        }
        else {
            instances.map((audio) => {
                audio.muted = false;
                audio.play();
            });
        }
    }
    muteOff(sound) {
        const soundbox = this.soundboxMap[sound.key];
        soundbox.instances.map((audio) => {
            audio.muted = true;
        });
    }
    stop() {
        this.pause = true;
        for (let item of this.sounds) {
            item.play = false;
        }
    }
    activateGroup(group) {
        var _a;
        this.pause = false;
        if (!group) {
            const index = random(0, this.sounds.length - 1);
            this.sounds.map((item, i) => {
                if (i === index) {
                    item.play = true;
                    item.level = this.defaultLevel;
                    this.muteOn(item);
                }
                else {
                    item.play = false;
                }
            });
        }
        else {
            this.sounds.map((item) => {
                item.play = false;
            });
            const index = random(0, this.presets[group].length - 1, ((_a = this.presetSelected) === null || _a === void 0 ? void 0 : _a.group) == group ? this.presetSelected.index : undefined);
            for (let preset of this.presets[group][index]) {
                for (let item of this.sounds) {
                    if (item.key == preset.key) {
                        item.play = true;
                        item.level = preset.level;
                        this.muteOn(item);
                    }
                }
            }
            this.presetSelected = {
                group: group,
                index: index
            };
        }
    }
}
SoundsCtrl.$inject = ['$scope', '$filter', '$q'];
const appModule = ng.module('app');
appModule.requires.push('rzSlider');
appModule.component('gameSounds', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SoundsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('sounds/');
    }]);
